export const getToPriceFacetCountsWhenFromPriceIs = (priceRanges, selectedValue = -1)=>{
    let tally = 0;
    return [
        ...priceRanges
    ].filter((priceRange)=>selectedValue === -1 || (priceRange.to ? priceRange.to > selectedValue : false)).map((priceRange)=>({
            value: priceRange.to,
            doc_count: tally += priceRange.count
        })).reduce((accumulator, currentPriceRange)=>{
        const currentValue = currentPriceRange.value;
        if (currentValue !== undefined) {
            accumulator[String(currentValue)] = currentPriceRange.doc_count;
        }
        return accumulator;
    }, {});
};
