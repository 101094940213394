import { getSearchAPIData } from "./api";
export const getHomePageVehiclesData = async ()=>{
    const res = await getSearchAPIData();
    const makesData = res.facets.makes;
    const makes = makesData.map((make)=>({
            name: make.name,
            stock: make.doc_count,
            models: make.models.map((model)=>({
                    name: model.name,
                    stock: model.doc_count
                }))
        }));
    const carTypes = {
        ...res.facets.bodyType,
        electric: res.facets.fuelType.electric
    };
    const monthlyPriceBucket = {
        ...res.facets.monthlyPriceBucket
    };
    const priceBucket = {
        ...res.facets.priceBucket
    };
    const vehiclesData = {
        totalStock: res.searchResultsCount,
        makes,
        carTypes,
        monthlyPriceBucket,
        priceBucket
    };
    return vehiclesData;
};
