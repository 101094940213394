import { datadogLogs } from "@datadog/browser-logs";
import { ChannelValue } from "./shared-customer-journey.types";
export const getParamValue = (param)=>{
    var ref;
    if (!window.location) return null;
    const params = (ref = window.location) === null || ref === void 0 ? void 0 : ref.search;
    const searchParams = new URLSearchParams(params);
    return searchParams.get(param);
};
export const getChannel = ()=>{
    var ref;
    if (!window.location) return ChannelValue.direct;
    const params = window.location.search;
    datadogLogs.logger.info("AWIN URL search params", {
        location: window.location,
        params: params
    });
    const searchParams = new URLSearchParams(params);
    const awc = searchParams.has("awc");
    const CID = (ref = searchParams.get("CID")) === null || ref === void 0 ? void 0 : ref.toLowerCase();
    const gclid = searchParams.has("gclid");
    const fbclid = searchParams.has("fbclid");
    const channelConditionsList = [
        {
            priority: 1,
            channelName: "ppcGeneric",
            condition: gclid && !(CID === null || CID === void 0 ? void 0 : CID.includes("brand")),
            channelValue: ChannelValue.ppcGeneric
        },
        {
            priority: 2,
            channelName: "ppcBrand",
            condition: gclid && !!(CID === null || CID === void 0 ? void 0 : CID.includes("brand")),
            channelValue: ChannelValue.ppcBrand
        },
        {
            priority: 3,
            channelName: "facebook",
            condition: fbclid && !!(CID === null || CID === void 0 ? void 0 : CID.includes("social")) && (CID.includes("meta") || CID.includes("facebook")),
            channelValue: ChannelValue.facebook
        },
        {
            priority: 4,
            channelName: "tiktok",
            condition: !!(CID === null || CID === void 0 ? void 0 : CID.includes("tiktok")),
            channelValue: ChannelValue.tiktok
        },
        {
            priority: 5,
            channelName: "awin",
            condition: awc,
            channelValue: ChannelValue.awin
        },
        {
            priority: 6,
            channelName: "direct",
            condition: !CID && !awc,
            channelValue: ChannelValue.direct
        }
    ];
    const channelConditionValues = channelConditionsList.filter((channelCondition)=>channelCondition.condition);
    return channelConditionValues.length > 0 ? channelConditionValues.sort((a, b)=>a.priority - b.priority)[0].channelValue : ChannelValue.direct;
};
export const getCookie = ({ name  })=>{
    var ref;
    return (ref = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)) === null || ref === void 0 ? void 0 : ref.pop();
};
export const cookieBuilder = (key, data, expiryDay)=>{
    datadogLogs.logger.info("AWIN cookie data", {
        data: data
    });
    const expiryDate = new Date();
    const cookieValue = JSON.stringify(data);
    const daysToMilliseconds = expiryDay * 8.64e7;
    expiryDate.setTime(expiryDate.getTime() + daysToMilliseconds);
    const expires = `expires=${expiryDate.toUTCString()}`;
    return `${key}=${cookieValue};${expires};path=/`;
};
// Will only work for objects that are one level deep
export const compareObjects = (object1, object2)=>{
    for(const key in object1){
        // eslint-disable-next-line no-prototype-builtins
        if (object1.hasOwnProperty(key) && object1[key] !== object2[key]) {
            return false;
        }
    }
    for(const key1 in object2){
        // eslint-disable-next-line no-prototype-builtins
        if (object2.hasOwnProperty(key1) && object1[key1] !== object2[key1]) {
            return false;
        }
    }
    return true;
};
