import { getStoryBySlug } from "@cinch-nx/data-storyblok";
const isDev = process.env.STAGE_NAME !== "production";
export const getHomePageContentData = async (preview = false)=>{
    const story = await getStoryBySlug("home", {
        version: preview ? "draft" : "published",
        ...isDev && {
            cv: Date.now()
        }
    });
    return story ?? undefined;
};
