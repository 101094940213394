import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { getTrustpilotReviews } from "../api/get-reviews";
export function useTrustpilotReviews() {
    const [trustpilotData, setTrustpilotData] = useState();
    useEffect(()=>{
        getTrustpilotReviews().then((data)=>{
            const formattedData = {
                ...data,
                reviews: data.reviews.map((review)=>({
                        ...review,
                        description: truncateContent(review.description),
                        date: formatDate(review.date)
                    }))
            };
            setTrustpilotData(formattedData);
        });
    }, []);
    return trustpilotData;
}
export default useTrustpilotReviews;
/***
 * Truncates the provided str to the desiredLength.
 * @param {string} inputString - the string we want to truncate.
 * @param {number} desiredLength - the Desired Maximum length. defaults to 135.
 *
 */ function truncateContent(inputString, desiredLength = 135) {
    return inputString.length > desiredLength ? [
        ...inputString.slice(0, desiredLength),
        "..."
    ].join("") : inputString;
}
/**
 * Formats the provided date.
 * @param {string} date | the Date string to be formatted.
 * @returns the formatted string.
 */ function formatDate(date) {
    try {
        return format(parseISO(date), "d MMMM yyyy");
    } catch  {
        // RangeError: Invalid time value
        // Return existing value
        return date;
    }
}
