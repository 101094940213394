import { EventNames, TrackingEventTypes } from "@cinch-nx/shared-analytics";
import { datadogRum } from "@datadog/browser-rum";
import { Datadog } from "@cinch-nx/landing-ui-shared";
const { Actions , Context  } = Datadog;
const getValueOrAny = (value)=>value ? value : "any";
export function createTrackers(trackEvent) {
    return {
        onChange ({ trackEventLabel , trackEventAction , value , datadogActionName , trackEventSection  }) {
            trackEvent({
                eventName: `Homepage ${trackEventLabel} dropdown`,
                type: TrackingEventTypes.ADOBE,
                data: {
                    event: {
                        name: EventNames.click,
                        category: "hero",
                        action: trackEventAction,
                        label: trackEventLabel,
                        value: value === null || value === void 0 ? void 0 : value.toLowerCase(),
                        section: trackEventSection === null || trackEventSection === void 0 ? void 0 : trackEventSection.toLowerCase()
                    }
                }
            });
            datadogRum.addAction(datadogActionName, {
                service: Context.HERO_SEARCH,
                squad: Context.SQUAD,
                page: window.location.pathname,
                value: value === null || value === void 0 ? void 0 : value.toLowerCase()
            });
        },
        onSubmit (selectedMake, selectedModel, price, trackEventSection) {
            let trackEventName = "Homepage filter search all cars";
            let actionName = "";
            const searchTrackEventValue = `search all cars make: ${getValueOrAny(selectedMake)}, model: ${getValueOrAny(selectedModel)}, ${getValueOrAny(price)}`;
            if (selectedMake) {
                trackEventName = "Homepage filter search now";
                actionName = selectedModel ? `${selectedMake},${selectedModel}` : `${selectedMake}`;
            }
            trackEvent({
                eventName: trackEventName,
                type: TrackingEventTypes.ADOBE,
                data: {
                    action: actionName,
                    event: {
                        name: EventNames.click,
                        category: "hero",
                        action: "submit",
                        label: "search all cars",
                        value: searchTrackEventValue,
                        section: trackEventSection === null || trackEventSection === void 0 ? void 0 : trackEventSection.toLowerCase()
                    }
                }
            });
            datadogRum.addAction(Actions.CLICK_SEARCH_CTA, {
                service: Context.HERO_SEARCH,
                squad: Context.SQUAD,
                page: window.location.pathname,
                value: searchTrackEventValue
            });
        }
    };
}
