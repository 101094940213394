import { getRecentAndRecommendVehicles } from "../data/get-recent-and-recommend-vehicles";
import { tagError } from "@core/datadog-server";
export const getPersonalisationEligibility = async (cookies)=>{
    try {
        const vehicleIds = cookies["cinch-recently-viewed-cars"];
        const cookiePreferences = cookies["cp"] ? JSON.parse(cookies["cp"]) : null;
        const hasFunctionalCookies = cookies && (cookiePreferences === null || cookiePreferences === void 0 ? void 0 : cookiePreferences.functional);
        const parsedVehicleIds = vehicleIds ? JSON.parse(vehicleIds) : [];
        const vehicleIdsAsAnArray = parsedVehicleIds.length > 0 ? parsedVehicleIds : [];
        let recentlyViewedListings = [];
        let similarListings = [];
        if (vehicleIdsAsAnArray.length > 0) {
            const data = await getRecentAndRecommendVehicles(vehicleIdsAsAnArray);
            recentlyViewedListings = data.recentlyViewedListings;
            similarListings = data.similarListings;
        }
        const hasPreviouslyViewedCars = vehicleIdsAsAnArray !== undefined && recentlyViewedListings.length > 0 && similarListings.length > 0;
        const showPersonalisedComponents = (hasPreviouslyViewedCars && hasFunctionalCookies) ?? false;
        return {
            showPersonalisedComponents,
            recentlyViewedListings,
            similarListings
        };
    } catch (error) {
        if (typeof error === "string" || error instanceof Error) {
            tagError(error);
        }
        console.log("there is an error with the getRecentAndRecommendVehicles call", error);
        return {
            showPersonalisedComponents: false,
            recentlyViewedListings: [],
            similarListings: []
        };
    }
};
