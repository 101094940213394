export function fillInMissingRangeFacets(staticIncrements, dynamicRangeFacets) {
    const updatedFacets = {
        ...dynamicRangeFacets
    };
    staticIncrements.forEach(({ rangeKey  })=>{
        if (dynamicRangeFacets[rangeKey] === undefined) {
            updatedFacets[rangeKey] = 0;
        }
    });
    return updatedFacets;
}
