import { Env, readFromEnv } from "@cinch-nx/environments";
const MAX_VEHICLE_LISTINGS = 6;
export const SEARCH_URL = readFromEnv(Env.SearchServiceUrl);
export const getRecentAndRecommendVehicles = async (vehicleIds)=>{
    var ref;
    let recentVehicleResults;
    let similarVehicleResults;
    try {
        const data = await fetch(`${SEARCH_URL}/vehicles?vehicleIds=${vehicleIds.join(",")}&pageSize=${MAX_VEHICLE_LISTINGS}`);
        recentVehicleResults = await data.json();
    } catch  {
        throw new Error("Something went wrong with the fetching of vehicle listings");
    }
    try {
        const similarVehicleData = await fetch(`${SEARCH_URL}/similar?vehicleId=${vehicleIds[0]}&pageSize=${MAX_VEHICLE_LISTINGS}`);
        similarVehicleResults = await similarVehicleData.json();
    } catch  {
        throw new Error("Something went wrong with the fetching of similiar vehicles");
    }
    const vehicleListings = (recentVehicleResults === null || recentVehicleResults === void 0 ? void 0 : (ref = recentVehicleResults.vehicleListings) === null || ref === void 0 ? void 0 : ref.filter((vehicle)=>!vehicle.isReserved)) || [];
    const similarVehicleListing = (similarVehicleResults === null || similarVehicleResults === void 0 ? void 0 : similarVehicleResults.vehicleListings) || [];
    const orderedVehicles = vehicleIds.reduce((vehicles, vehicleId)=>{
        const vehicleToOrder = vehicleListings.find((recentVehicle)=>recentVehicle.vehicleId === vehicleId);
        if (vehicleToOrder) {
            vehicles = [
                ...vehicles,
                vehicleToOrder
            ];
        }
        return vehicles;
    }, []);
    return {
        recentlyViewedListings: orderedVehicles,
        similarListings: similarVehicleListing
    };
};
