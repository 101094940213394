export function decorateOptions(options, count) {
    const shouldDecorate = options.findIndex((option)=>option.value === "any") === -1;
    if (shouldDecorate) {
        options.unshift({
            label: `Any`,
            value: "any",
            count: `${count}`
        });
    }
    return options;
}
